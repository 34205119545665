//urdu fony
@font-face {
  font-family: UrduFont;
  src: url("./venders/Jameel\ Noori\ Nastaleeq\ Regular.ttf");
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Montserrat", "UrduFont";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.App {
  overflow: hidden;
}

html {
  // This defines what 1rem is
  font-size: 72.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    // width < 1200?
    font-size: 66.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 60%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 85%; //1rem = 12, 12/16
  }
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  @include respond(tab-port) {
    padding: 0;
  }
  a,
  a:hover,
  a:focus,
  a:active {
    color: inherit;
    text-decoration: none;
  }
}

// .active li{
//     background-color:$color-primary !important;
//     & svg{
//         fill: white !important;
//     }
// }
.active {
  background-color: white !important;
  color: $color-primary !important;
  & svg {
    fill: $color-primary !important;
  }
}

// .primary{
// 	color: $color-primary;
// }

// side scrollbar css start
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-secondary-light;
  // border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-secondary;
}

// side scrollbar css end

//logo css start
.Simple_Solutions_Logo {
  word-spacing: 3px;
  font-weight: bolder;
  border-bottom: 0.2rem solid #28528f;
  padding: 0.2rem;
  & .Simple_Solutions_Logo_child {
    font-size: 1rem !important;

    animation: 3s ease-in 1s infinite reverse both running SimpleSolutionsLogo;
  }
}

@keyframes SimpleSolutionsLogo {
  from {
    color: #28528f;
  }
  to {
    color: #7ec5e4;
  }
}

//logo css end

//Buttoncolors

.colorPrimary {
  background-color: $color-primary-light !important;
  &:hover {
    background-color: $color-primary !important;
  }
}

.colorSecondary {
  background-color: $bg-color !important;
  &:hover {
    background-color: $color-secondary-light !important;
  }
}
//toggle start
.toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  &_BsTx {
    align-items: center;
    display: flex;
    height: 1rem;
    justify-content: center;
    position: relative;
    width: 3rem;
    color: $color-white;
  }
  &_thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    position: absolute;
    top: 0;
    left: 0.6rem;
    width: 3.4rem;
    height: 2.7rem;
    @include respond(tab-port) {
      height: 3.7rem;
    }
    border: 0.1rem solid $color-primary;
    border-radius: 30%;
    background-color: $color-primary;
    box-sizing: border-box;
    transition: all 0.25s ease;
  }
  .thumb-active {
    left: 4rem !important;
  }
  &_screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 0.1rem;
    margin: -0.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0.1rem;
  }
  &_track {
    width: 7rem;
    height: 2.4rem;
    padding: 0;
    border-radius: 3rem;
    background-color: $color-primary;
    transition: all 0.2s ease;

    &_check {
      position: absolute;
      width: 1.4rem;
      height: 1rem;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      line-height: 0;
      left: 0.8rem;
      transition: opacity 0.25s ease;
    }
    &_x {
      position: absolute;
      width: 2rem;
      height: 1rem;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      line-height: 0;
      right: 1rem;
    }
  }
}

//toggle end

.scrollactive {
  background-color: $color-primary;
  & span {
    color: $color-white;
  }
}

// urdu fornt for urdu mode
.UrduFontInputLabel {
  font-size: 1.6rem !important;
  @include respond(tab-land) {
    font-size: 1.6rem !important;
  }
  & .MuiInputLabel-formControl,
  .MuiFormLabel-root {
    font-size: 1.6rem !important;
    font-family: "UrduFont" !important;
    @include respond(tab-land) {
      font-size: 1.6rem !important;
    }
  }
}
